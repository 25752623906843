<!--
* 创建人：罗兴
* 日 期：
* 描 述：
-->
<!--view 模板-->
<template>
  <div class="container">
    <div class="toptj">
      <div class="toptitle">综合测评计算</div>
    </div>
    <div class="selbox">
      <div class="sel-left">
        学&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;期：
      </div>
      <el-radio-group v-model="xn" size="medium">
        <el-radio-button
          v-for="(item, index) in xnlist"
          :key="index"
          :label="item.ksnf + '-' + item.jsnf"
        ></el-radio-button>
      </el-radio-group>
    </div>
    <div class="selbox">
      <div class="sel-left">
        学&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;院：
      </div>
      <el-checkbox-group v-model="bm" size="medium" @change="bmnoclick">
        <el-checkbox-button label="不限">不限</el-checkbox-button>
        <el-checkbox-button
          v-for="(item, index) in bmlist"
          :label="item.bmbh"
          :key="index"
          >{{ item.bmmc }}</el-checkbox-button
        >
      </el-checkbox-group>
    </div>
    <div class="selbox">
      <div class="sel-left">
        年&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;级：
      </div>
      <el-checkbox-group v-model="nj" size="medium" @change="njonclick">
        <el-checkbox-button label="不限">不限</el-checkbox-button>
        <el-checkbox-button
          v-for="(item, index) in njlist"
          :label="item.nj"
          :key="index"
          >{{ item.nj }}</el-checkbox-button
        >
      </el-checkbox-group>
    </div>
    <div class="main">
      <el-row :gutter="20">
        <el-col :span="8">
          <div class="item">
            <div class="ititle">德育素质占比（%）：</div>
            <el-input-number
              v-model="dyzb"
              :min="1"
              :max="100"
              label="描述文字"
            ></el-input-number>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="item">
            <div class="ititle">德智育素质占比（%）：</div>
            <el-input-number
              v-model="zyzb"
              :min="1"
              :max="100"
              label="描述文字"
            ></el-input-number>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="item">
            <div class="ititle">体育素质占比（%）：</div>
            <el-input-number
              v-model="tyzb"
              :min="1"
              :max="100"
              label="描述文字"
            ></el-input-number>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="item">
            <div class="ititle">美育素质占比（%）：</div>
            <el-input-number
              v-model="myzb"
              :min="1"
              :max="100"
              label="描述文字"
            ></el-input-number>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="item">
            <div class="ititle">劳育素质占比（%）：</div>
            <el-input-number
              v-model="lyzb"
              :min="1"
              :max="100"
              label="描述文字"
            ></el-input-number>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="item">
            <div class="ititle">能力素质占比（%）：</div>
            <el-input-number
              v-model="nlzb"
              :min="1"
              :max="100"
              label="描述文字"
            ></el-input-number>
          </div>
        </el-col>
      </el-row>
      <div style="text-align: center">
        <el-button
          type="primary"
          style="width: 400px; margin: 20px auto"
          @click="ksjs"
          >开始计算</el-button
        >
      </div>
      <el-progress :percentage="current"></el-progress>
    </div>
  </div>
</template>
<!-- javascript脚本-->
<script>
import { getXNList } from '../../api/system'
import { getStuBM, getZDNJ } from '../../api/jcxx'
import { SaveJS, GetCurrent } from '../../api/zhcp'
export default {
  data() {
    return {
      dyzb: 20,
      zyzb: 50,
      tyzb: 10,
      myzb: 5,
      lyzb: 5,
      nlzb: 10,
      xnlist: [],
      xn: '',
      current: 0,
      timer: null,
      bmlist: [],
      bm: [],
      njlist: [],
      nj: [],
    }
  },
  created() {
    getXNList().then((res) => {
      if (res.code === 200) {
        this.xnlist = res.data
        this.xn = res.data[0].ksnf + '-' + res.data[0].jsnf
        this.getDataPageList()
      }
    })
    getStuBM().then((res) => {
      if (res.code === 200) {
        this.bmlist = res.data
      }
    })
    getZDNJ().then((res) => {
      if (res.code === 200) {
        this.njlist = res.data
      }
    })
  },
  methods: {
    ksjs() {
      if (
        this.dyzb +
          this.zyzb +
          this.tyzb +
          this.myzb +
          this.lyzb +
          this.nlzb !==
        100
      ) {
        this.$message.error('请填写正确的比例！')
        return
      }
      if (!this.xn) {
        this.$message.error('请选择要计算的学年！')
        return
      }
      if (!this.nj) {
        this.$message.error('请选择年级！')
        return
      }
      if (!this.bmlist.length === 0) {
        this.$message.error('请选择学院！')
        return
      }
      if (!this.njlist.length === 0) {
        this.$message.error('请选择年级！')
        return
      }
      var njtmp = []
      if (this.nj.length === 1 && this.nj[0] === '不限') {
        njtmp = null
      } else {
        njtmp = this.nj
      }
      console.log(njtmp)
      var bmtmp = []
      if (this.bm.length === 1 && this.bm[0] === '不限') {
        bmtmp = null
      } else {
        bmtmp = this.bm
      }
      this.$message('计算中，请耐心等待')
      this.current = 0
      SaveJS({
        dyzb: this.dyzb,
        zyzb: this.zyzb,
        tyzb: this.tyzb,
        myzb: this.myzb,
        lyzb: this.lyzb,
        nlzb: this.nlzb,
        xn: this.xn,
        nj: njtmp,
        bm: bmtmp,
      }).then((res) => {
        if (res.code === 200) {
          this.$message.success('计算完成！')
          clearInterval(this.timer)
          this.current = 100
        }
      })
      this.timer = setInterval(this.getnum, 1000)
    },
    getnum() {
      GetCurrent().then((res) => {
        if (res.code === 200) {
          this.current = parseFloat(
            ((res.data.current / res.data.total) * 100).toFixed(1)
          )
        }
        if (res.data.current >= res.data.total) {
          this.current = 100
          clearInterval(this.timer)
        }
      })
    },
    bmnoclick(e) {
      if (e.length === 0) {
        this.bm.push('不限')
      }
      if (e.length > 1) {
        if (e[e.length - 1] === '不限') {
          this.bm = ['不限']
        } else {
          let index = -1
          for (let i = 0; i < e.length; i++) {
            if (e[i] === '不限') {
              index = i
            }
          }
          if (index >= 0) {
            this.bm.splice(index, 1)
          }
        }
      }
    },
    njonclick(e) {
      if (e.length === 0) {
        this.nj.push('不限')
      }
      if (e.length > 1) {
        if (e[e.length - 1] === '不限') {
          this.nj = ['不限']
        } else {
          let index = -1
          for (let i = 0; i < e.length; i++) {
            if (e[i] === '不限') {
              index = i
            }
          }
          if (index >= 0) {
            this.nj.splice(index, 1)
          }
        }
      }
    },
  },
  mounted() {},
}
</script>
<!-- CSS样式 -->
<style>
.container {
  width: 100%;
  height: 100%;
  max-height: 100%;
  background-color: #fff;
  border-radius: 3px;
  border: 1px solid #ccc;
  position: relative;
  overflow: hidden;
}
.main {
  margin: 40px auto;
}
.ititle {
  line-height: 40px;
  width: 200px;
  text-align: right !important;
}
.item {
  margin-bottom: 5px;
  display: flex;
}
.toptitle {
  font-weight: 700;
  margin: 20px;
}
.selbox {
  display: flex;
  margin: 20px;
  align-items: flex-start;
}
.sel-left {
  color: #ccc;
  width: 100px;
  margin-top: 20px;
  height: 34px;
  line-height: 34px;
}
.el-radio-button__inner {
  border: none !important;
  font-size: 16px !important;
  border-radius: 0 !important;
}
.el-radio-button {
  margin-left: 40px;
  margin-top: 20px;
}
.el-checkbox-button__inner {
  border: none !important;
  font-size: 16px !important;
  border-radius: 0 !important;
}
.el-checkbox-button {
  margin-left: 40px;
  margin-top: 20px;
}
</style>
